import React, { useEffect, useState } from 'react'
import './productForm.css'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { app } from '../Fire';
import { doc, setDoc, getFirestore, collection, serverTimestamp, query, getDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import Progress from '../Progress/Progress';
import { async } from '@firebase/util';
import { useParams } from 'react-router-dom';
import { fabMap } from '../App';
import { FaMap } from 'react-icons/fa';




function ProductForm(props) {


  var duri;

  
  
  const db = getFirestore(app)

  let auth = getAuth(app)




  const def= "Please Select"

  let params = useParams()

  const [pname, setpname] = useState("")
  const [price, setprice] = useState(0)
  const [quantity, setquantity] = useState(0)
  const [category, setcategory] = useState(def)
  const [fabric, setfabric] = useState(def)
  const [dimension, setdimension] = useState("")
  const [desc, setdesc] = useState("")
  const [fabarr, setfabarr] = useState([])
  const [catarr, setcatarr] = useState([])
  const [prog, setprog] = useState(false)
  const [color, setcolor] = useState("")
  
  const [colorarr, setcolorarr] = useState([])

  const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const makeRandom = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }



  const [myimages, setmyimages] = useState([])
  const storage = getStorage(app)


  function deleteImage(obj) {

    const desertRef = ref(storage, '' + obj.path);

    // Delete the file
    deleteObject(desertRef).then(() => {

      var index = myimages.indexOf(obj);
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        myimages.splice(index, 1);
      }

      let some = [...myimages]
      setmyimages(some)

      alert("File Deleted")
    }).catch((error) => {
      alert(error)
    });
  }


  function uploadfun(image) {
    setprog(true)
    const id = auth.currentUser.uid
    const storageRef = ref(storage, `/Products/${id}/${"" + makeid(20)}`)
    const uploadTask = uploadBytesResumable(storageRef, image);


    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(storageRef.fullPath)
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        alert(error.message)
        setprog(false)
        // Handle unsuccessful uploads
      },
      () => {

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          duri = downloadURL
          setprog(false)
          let some = [...myimages]
          some.push({ img: downloadURL, path: storageRef.fullPath })
          setmyimages(some)
          console.log('File available at', myimages);

        });
      }
    );


  }




  async function addData() {


    let months = ['J','F','M','A','M','J','J','A','S','O','N','D']

    let date = new Date()

    let productid = date.getFullYear()+'' + (date.getMonth()+1) +months[date.getMonth()]+makeRandom(4)
    
    setprog(true)

    try {
      await setDoc(doc(db, "products", "" + productid), {
        name: "" + pname,
        createdAt: serverTimestamp(),
        description: "" + desc,
        category: "" + category,
        productId: productid,
        price: parseInt("" + price),
        isAvailable: true,
        color: color,
        rating: 5.0,
        images: myimages,
        quantity: parseInt(quantity),
        fabric: "" + fabric,
        dimension: "" + dimension,
        sold: 0,
        type: ""+params.type
      });

      alert("Product Added")
      window.history.back()
      setprog(false)

    } catch (e) {
      alert(e)
      setprog(false)
    }



  }


  useEffect(() => {

  
 
    getExtras()
  
  }, [])
  



  async function getExtras(){
    setprog(true)
    let temp1 = [{name: def, key: def}]
    let temp2 = [{name: def, key: def}]
    let colortemp = [{name: def, key: def}]
    try{

      props.fabricMap.forEach((val,key) => {
     
        let fabModel = {name: val, key: key}
        temp1.push(fabModel)
      });
      setfabarr(temp1)

  

      props.colorMap.forEach((val, key)=>{

        let colorModel = {name: val.name, key: key}
        colortemp.push(colorModel)
          console.log(val, key)
      })

      setcolorarr(colortemp)

   


      if(params.type == "saree"){
         props.sareeMap.forEach((val, key)=>{
          let catModel = {name: val, key: key}
          temp2.push(catModel)
        })
        setcatarr(temp2)

      }else if(params.type == "blouse"){
      props.blouseMap.forEach((val, key)=>{
          let catModel = {name: val, key: key}
          temp2.push(catModel)
        })
        setcatarr(temp2)
        
      }

    //   const docref = doc(db, "extras", params.type+"types")
    //   const fabricRef = doc(db, "extras", "fabrics")
    //   const colorRef = doc(db,"extras", "mycolors")
    //   let fabricsnapshot = await getDoc(fabricRef)
    //   let colorsnapshot = await getDoc(colorRef)
    //   let snapshot = await getDoc(docref)
    // //  console.log(fabricsnapshot.data())

    //   setcolor(colorsnapshot.data().colors[0].code)
    //   setcolorname(colorsnapshot.data().colors[0].name)
    //   setfabric(fabricsnapshot.data().types[0])
    //   setcategory(snapshot.data().types[0])
    //   console.log(snapshot.data())
    //   setfabarr(fabricsnapshot.data().types)
    //   setcatarr(snapshot.data().types)
    //   setcolarr(colorsnapshot.data().colors)
    
      setprog(false)

    }catch(e){
      setprog(false)
      alert(e)
    }
    
  }

  function validate() {


    if (pname == "" || category == "" ||
    fabric == "" || dimension == "" || desc == "" || color == def) {

      alert("Please Enter All Feilds. Invalid Data")

    }else if(category == def){
      alert("Please Select Category")
    }else if(fabric == def){
      alert("Please Select Fabric")
    }
     else if(quantity <= 0 || price <= 0){
      alert("Quantity and price cannot be less than 0 or 0")
    }
    else if (myimages.length == 0) {
      alert("Please Add Images")
    } else {
      addData()
    }

  }


  

  return (

    <div className="container">


      <h3 style={{marginTop: '80px'}}>Add {params.type}</h3>

      {
        prog ? <Progress/> : null
      }
      <div style={{width: '70vw'}}>
        <div>
        
            <label >Product Name</label>
      
          
            <input type="text"
              value={pname}
              onChange={(x) => {
                setpname(x.target.value)
              }}
              placeholder="Product name.." />
        
        </div>


        <div className="row">
          <div className="col-25">
            <label >Product Category</label>
          </div>
          <div className="col-75">

            <select onChange={(x)=>{
           
                 setcategory(x.target.value)
            }}>

              {
                catarr.map((val, index)=>{
                  return(
                    <option key={index} value={val.key}>{val.name}</option>
                  )
                })
              }

            </select>
            {/* <input type="text"
              value={category}
              onChange={(x) => {
                setcategory(x.target.value)
              }}
              placeholder="Product Category" /> */}
          </div>
        </div>



        <div className="row">
          <div className="col-25">
            <label >Product Fabric</label>
          </div>
          <div className="col-75">


            <select onChange={(x)=>{

              if(x.target.value != ""){
                setfabric(x.target.value)
              }else{
                setfabric(def)
              }
            }}>
              {
               fabarr.map((val, index)=>{
                  return(
                    <option key={index} value={val.key}>
                      {val.name}</option>
                  )
                 
                })
              }
            
            </select>
          </div>
        </div>


        <div className="row">
          <div className="col-25">
            <label >Product Dimension</label>
          </div>
          <div className="col-75">
            <input type="text"
              value={dimension}
              onChange={(x) => {
                setdimension(x.target.value)
              }}
              placeholder="Product Dimension" />
          </div>
        </div>


        <div className="row">
          <div className="col-25">
            <label >Product Price</label>
          </div>
          <div className="col-75">
            <input type='number'
              value={price}
              onChange={(x) => {
                setprice(x.target.value)
              }}
              placeholder="Product Price" />
          </div>
        </div>


        <div className='row'>
          <div className='col-25'>
            <label>Select Color</label>
          </div>
            
            <div className='col-75'>

            <select onChange={(x) => {
            
                setcolor(x.target.value)  }}>
        {
          colorarr.map((val, index) => {
            return (
      
              <option
               key={index}
                value={val.key} > 
       
          
                {val.name} </option>
       
            )

          })
        }

      </select>

            </div>

        </div>

        <div className="row">
          <div className="col-25">
            <label >Product Quantity</label>
          </div>
          <div className="col-75">

            <input type='number'
              value={quantity}
              onChange={(x) => {
                setquantity(x.target.value)
              }}
              placeholder="Product Quantity" />
          </div>
        </div>

        <div className="row">
          <div className="col-25">
            <label >Description</label>
          </div>
          <div className="col-75">
            <textarea
              value={desc}
              onChange={(x) => {
                setdesc(x.target.value)
              }}
              placeholder="Write Product Description.." style={{ height: '200px' }}></textarea>
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          style={{ margin: '12px 0px 12px 0px' }}

          name="myImage"

          onChange={(event) => {

            const [file] = event.target.files;


            uploadfun(event.target.files[0])

            console.log(event.target.files[0])

          }}
        />


        <div style={{ display: 'flex', flexDirection: 'row' }}>

          {
            myimages.map((val, key) => {
              return <div
                style={{
                  display: 'flex',

                  margin: '12px',
                  flexDirection: 'column'
                }}
                key={key}>
                <img

                  style={{ width: '100px', height: '100px' }}
                  src={val.img} />


                <button
                  onClick={() => {

                    deleteImage(val)
                  }}
                >
                  Delete</button>
              </div>
            }


            )

          }
        </div>


        <div className="row">

          <button
            onClick={() => {

              validate()
              console.log(myimages)
            }}
            style={{ marginTop: '4px' }}
            className='mybuttons1'>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default ProductForm