import React, { useEffect, useState } from 'react'
import './Drawer.css'
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { BsEye } from "react-icons/bs";
import { BiBox, BiColorFill, BiLogOut } from "react-icons/bi";
import { FiShoppingBag } from "react-icons/fi";
import { GoTag } from "react-icons/go";




import { app } from '../Fire';
import { FaUsers } from 'react-icons/fa';




function Drawer(props) {


    let auth = getAuth(app)
    let navigate = useNavigate()

    let blouse = { name: 'Blouses', icon: GoTag, navigate: '/viewblouse' }
    let color = { name: 'colors', icon:  BiColorFill, navigate: '/viewcolor' }
    let orders = {name: 'orders' , icon: BiBox, navigate: '/orders'}
    let logout = { name: 'logout', icon: BiLogOut, navigate: '/' }
    let products = { name: 'Sarees', icon: FiShoppingBag, navigate: '/myproducts' }

    const [list, setlist] = useState([products, blouse, orders, color, logout])


    useEffect(() => {
      if(localStorage.getItem('select') != null && localStorage.getItem('select') != '/'){
          navigate(''+localStorage.getItem('select'))
      }else{
          localStorage.setItem('select', '/myproducts')
      }
    }, [])
    

 

    function open() {

        if(document.getElementById('mySidenav').style.width == '250px'){
            
            close()
        }else{
            document.getElementById("mySidenav").style.width = "250px";
            document.getElementById('main').style.marginLeft = '250px'
        }
    
    }
    function close() {
        document.getElementById("mySidenav").style.width = "0px";
        document.getElementById('main').style.marginLeft = '0px'
    }

    return (
        <div >


            <div id="mySidenav" className="sidenav">



                    <div style={{ display: 'flex', alignItems: 'center', marginBottom:'20px'}}>

                    <img 
                    style={{height: '60px', width: '60px', margin: '12px', backgroundColor: 'white', borderRadius: '120px' }}
                    src='mylogo.png'/>

                    <p style={{color: 'white'}}>Vedha Fasions</p>
                    </div>

                {
                    list.map((val, index) => {
                        return (
                            <div
                            style={{background: localStorage.getItem('select')  == val.navigate  ? 'purple' : null}}
                            onClick={() => {
                                localStorage.setItem('select', val.navigate)
                                if(val.navigate == '/'){
                                    auth.signOut().then((user) => {
                                        navigate('/')
                                    }).catch((e) => {
                                        alert(e)
                                    })
                                    close()
                                }else{
                                    navigate(''+val.navigate)
                                    close()
                                }
                             
                            }}
                           
                             key={index} className='icondiv'>

                                <val.icon className='myicon'/>

                                <a >{val.name}</a>
                                </div>
                        )
                    })
                }


            </div>

                <div style={{backgroundColor: '#e1d0fe',
                padding: '6px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                 display: 'flex', flexDirection: 'row',
                 justifyContent: 'space-between',
            alignItems: 'center'}}>


                <div style={{display: 'flex', alignItems: 'center'}}>
                <AiOutlineMenu
                size={24}
                style={{ margin: '8px', color: '#00000', fontWeight: 'bolder' }}
                onClick={() => {
                    open()
                }}
            />

            <p style={{color: '#000000',  fontFamily: 'sans-serif', marginLeft: '8px'}}>{props.name}</p>

                </div>



                <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{fontSize: '12px', marginLeft: '6px', marginRight: '6px'}}>Welcome Admin</p>
            
                <FaUsers style={{marginRight: '20px'}}/>
                 </div>
    

          
                </div>
         

        </div>
    )
}

export default Drawer