import { async } from '@firebase/util'
import { doc, getDoc, getFirestore, increment, updateDoc, writeBatch } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Drawer from '../Drawer/Drawer'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

function OrderDetails(props) {


    const params = useParams()
    const [orders, setorders] = useState([])
    const [mData, setmData] = useState()
    const [prog, setprog] = useState(false)

    const db = getFirestore(app)

    useEffect(() => {
        getOrders()
    }, [])


    async function orderCompleteFunc(docId, index) {
        try {
            setprog(true)
            let temp = [...orders]
            temp[index].status = "completed"
            setorders(temp)
            let batch = writeBatch(db)

            const orderRef = doc(db, "orders", "" + docId);
            batch.update(orderRef, {
                'produts': temp
            })

            await batch.commit()
            setprog(false)
            alert("Order Updated")
        } catch (e) {
            let temp = [...orders]
            temp[index].status = "pending"
            setorders(temp)
            setprog(false)
            setprog(false)

            alert(e)
        }

    }


    async function orderRejectFun(docId, index) {
        try {
            const batch = writeBatch(db)
            setprog(true)
            let temp = [...orders]
            temp[index].status = "rejected"
            setorders(temp)

            let quantity = temp[index].quantity
            let productId = temp[index].pid
            const productRef = doc(db, "products", "" + productId);
            batch.update(productRef, {
                quantity: increment(quantity)
            })
            const washingtonRef = doc(db, "orders", "" + docId);
            batch.update(washingtonRef, {
                produts: temp
            })
            await batch.commit()
            setprog(false)
            alert("Order Rejected")

        } catch (e) {
            setprog(false)
            alert(e)
        }
    }

    // async function orderCancelFunc(docId, productId, index) {
    //     try {
    //         setprog(true)
    //         let temp = [...orders]
    //         temp[index].status = "cancelled"
    //         setorders(temp)
    //         let batch = writeBatch(db)
    //         const productRef = doc(db, "products", "" + productId);
    //         const orderRef = doc(db, "orders", "" + docId);
    //         batch.update(orderRef, {
    //             'produts': temp
    //         })
    //         batch.update(productRef, {
    //             quantity: increment(1)
    //         })
    //         await batch.commit()
    //         setprog(false)
    //         alert("Order Updated")
    //     } catch (e) {
    //         let temp = [...orders]
    //         temp[index].status = "completed"
    //         setorders(temp)
    //         setprog(false)

    //         alert(e)
    //     }

    // }

    async function getOrders() {
        setprog(true)
        try {

            const docRef = doc(db, "orders", params.id)
            const snapshot = await getDoc(docRef)
            //  setorders(snapshot.data().produts)
            setmData(snapshot.data())

            let d1
            let temp = []
            snapshot.data().produts.forEach((x) => {


                d1 = x
                props.fabricMap.forEach((val, key) => {
                    if (d1.fabric == key) {
                        d1.fabric = val
                    }
                })

                props.blouseMap.forEach((val, key) => {
                    if (d1.category == key) {
                        d1.category = val
                    }
                })

                props.colorMap.forEach((val, key) => {
                    if (d1.color == key) {
                        d1.color = val.name
                    }
                })

                temp.push(d1)

            })

            setorders(temp)


            setprog(false)
            console.log(snapshot.data().produts)

        } catch (e) {
            setprog(false)
            alert(e)
        }
    }


    return (
        <div style={{
            padding: '12px'
        }}>

            {
                prog ? <Progress /> : null
            }

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <button
                    onClick={() => {
                        window.history.back()
                    }}
                    style={{
                        color: 'purple', background: 'none', outline: 'none', borderWidth: '0.2px',
                        marginTop: '12px', marginBottom: '12px',
                        padding: '4px', paddingLeft: '12px', paddingRight: '12px', borderRadius: '3px'
                    }}
                >Back</button>
                <h3
                    style={{ marginLeft: '12px' }}>Summary</h3>

            </div>


            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                margin: '12px 12px 0px 12px'
            }}>
                <p>Mobile:- {mData?.mobile}</p>
                <p>Name:- {mData?.name}</p>
            </div>

            <p
                style={{ margin: '8px 12px 0px 12px', whiteSpace: 'pre-line' }}
            > Address:- {mData?.address.replaceAll(',', '\n')}</p>

            <h3 style={{ margin: '8px' }}>
                Order ID:-  {params.id}
            </h3>
            <div style={{
                display: 'flex', flexDirection: 'column'
            }}>


                <table className='tablebox2'>
                    <thead className='thead'>
                        <tr className="tablehead2">
                        <th>Product Id</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Fabric</th>
                            <th>Quantity</th>
                            <th>Color</th>
                            <th >Amount</th>

                        </tr>
                    </thead>

                    <tbody>

                        {
                            orders.map((val, index) => {
                                return (

                                  


                                        <tr key={index} className="tablerow2">
                                            <th>{val.pid}</th>
                                            <th >
                                                <img className="doc-img2" src={val.img} alt="" />
                                            </th>
                                            <th>{val.name}</th>
                                            <th>{val.fabric}</th>
                                            <th>{val.quantity}</th>
                                            <th>{val.color}</th>
                                            <th>{val.price}</th>
                                        </tr>

                                  

                                )


                            }

                            )


                        }



                    </tbody>
                </table>


            </div>


        </div>
    )
}

export default OrderDetails