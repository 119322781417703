import React from 'react'

function ProductDetails(props) {
    console.log(props)
    
  return (
    <div style={{padding: '12px'}}>


        <button
            onClick={()=>{
              props.onsubmit()
            }}
        >Back</button>
        
        <h1>{""+props.data.name}</h1>
        <p>{""+props.data.description}</p>
        <p>{"₹ "+props.data.price}</p>

 
    <div style={{display: 'flex'}}>
        <div style={{width: '100px', height: '100px', background: 'blue', color: 'white',
        display: 'flex', flexDirection: 'column', padding: '20px', margin: '12px',
        borderRadius: '12px',
      alignItems: 'center', justifyContent: 'center'}}>
            <p>Quantity</p>
            <h4>
             {""+props.data.quantity}
            </h4>
    
        </div>

        <div style={{width: '100px', height: '100px', background: 'red', color: 'white',
        display: 'flex', flexDirection: 'column', padding: '20px', margin: '12px',
        borderRadius: '12px',
      alignItems: 'center', justifyContent: 'center'}}>
            <p>Sold Out</p>
            <h4>
             {""+props.data.sold}
            </h4>
    
        </div>
    </div>

    <h4>Product Images</h4>

    <div style={{display: 'flex'}}>

    {
  props.data.images.map((val, key)=>{
    return <div key={key}>
       <img 
       style={{width: '200px', height: '200px', objectFit: 'contain', borderRadius: '12px'}}
       src={val.img}/>
    </div>
})
}
    </div>


      
        

    </div>
  )
}

export default ProductDetails