import React, { useState } from 'react'

import { getAuth, signInWithCredential, signInWithEmailAndPassword } from 'firebase/auth'

import './Login.css'
import { async } from '@firebase/util'
import { useNavigate } from 'react-router-dom'
import Progress from '../Progress/Progress'

function Login() {



  let navigate = useNavigate()

    const [email, setemail] = useState("")
    const [pass, setpass] = useState("")
   
    let auth =  getAuth()
    const [prog, setprog] = useState(false)


    async function loginFunc(){
      setprog(true)
        signInWithEmailAndPassword(auth, email, pass)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential)
          setprog(false)
          navigate('/myproducts')
       
        })
        .catch((error) => {
          const errorCode = error.code;
          setprog(false)
          const errorMessage = error.message;
          alert(error)
         
        });
    }
    

  return (


      <div>

      {
        prog ? <Progress/> : null
      }
            

        <div className='mymaindiv'>
            <div className='mychilddiv'>

                      <div className='mywrapper'>
                 
                      <h1 style={{color: 'white'}}>Vedha Fashions</h1>
                      <h4 style={{color: 'white'}}>Admin Login</h4>
                      <input className='myusername'  placeholder="Enter Email"
                      value={email} onChange={(x)=>{
                            setemail(x.target.value)
                      }}/>
                      <input
                         value={pass} onChange={(x)=>{
                            setpass(x.target.value)
                      }}
                      
                       className='myusername'  type='password' placeholder="Enter Password"/>                    
                      <button className='myadduserbtn'
                      onClick={()=>{
                          loginFunc()
                      }}
                      >Submit</button>
                      <p
                      style={{color: 'white', fontSize: '8px', marginTop: '4px'}}
                      >Contact Admin incase You don't Have Credentials</p>

                </div>
            </div>
        </div>
    </div>


  )
}

export default Login