import { type } from '@testing-library/user-event/dist/type'
import { getAuth } from 'firebase/auth'
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { AiOutlineCodepenCircle, AiOutlineDelete } from 'react-icons/ai'
import { BiPencil } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import Drawer from '../Drawer/Drawer'
import { app } from '../Fire'
import ProductDetails from '../ProductDetails/ProductDetails'
import Progress from '../Progress/Progress'
import SliderDrawer from '../SlideDrawer/SliderDrawer'
import './myproducts.css'

function Sarees(props) {



    let db = getFirestore(app)
    let auth = getAuth()

    let temparr = []

    let pname = ""
    let temp = []
    const [products, setproducts] = useState([])
    const [page, setpage] = useState(true)
    const [pdata, setpdata] = useState({})
    const [prog, setprog] = useState(false)

    const [search, setsearch] = useState("")
    const [fabarr, setfabarr] = useState([])

    let navigate = useNavigate()
    useEffect(() => {

        getProducts()


    }, [])


    async function deleteProduct(id, index) {
        setprog(true)
        try {


            await deleteDoc(doc(db, "products", id))
            alert("Deleted")
            setprog(false)

            let old = products
            old.splice(index,1)
            setproducts(old)

        } catch (e) {
            setprog(false)
            alert(e)
        }
    }


    async function searchFun(txt) {

        setprog(true)
        try {
            const q = query(collection(db, "products"),
            where('type', '==', 'saree'),
             where('name', '>=', txt), 
             where('name', '<=', txt + '\uf8ff'), limit(20))

            const snapshot = await getDocs(q)



            snapshot.forEach((x) => {


                let d1 = x.data()
                d1.id = x.id



                props.fabricMap.forEach((val, key) => {
                    if (d1.fabric == key) {
                        d1.fabric = val
                    }
                })

                props.blouseMap.forEach((val, key) => {
                    if (d1.category == key) {
                        d1.category = val
                    }
                })

                temp.push(d1)
        
            })
            setproducts(temp)
            setprog(false)

        } catch (e) {
            setprog(false)
            console.log(e)
            alert(e)
        }




    }



    async function getProducts() {

        setprog(true)
        try {


            // const docRef = doc(db, "extras", "blousetypes")
            // const snap = await getDoc(docRef)
            // let map = new Map(Object.entries(snap.data().mymap))


            const q = query(collection(db, "products"), where("type", "==", "saree"), orderBy("createdAt", "desc"))

            const snapshot = await getDocs(q)
            snapshot.forEach((doc1) => {



                let d1 = doc1.data()
                d1.id = "" + doc1.id
                props.fabricMap.forEach((element, key) => {
                    if (key == d1.fabric) {
                        d1.fabric = element
                    }
                })

                props.sareeMap.forEach((element, key) => {
                    if (key == d1.category) {
                        d1.category = element
                    }
                })





                temp.push(d1)
                console.log(d1.name)

            })


            console.log(temp[0])

            setproducts(temp)
            setprog(false)
        } catch (e) {
            alert(e)
            console.log(e)
            setprog(false)
        }


    }


    return (
        <div id='main'>



            <Drawer name='Saree' />


            {
                prog ? <Progress /> : null
            }


            <section  id='main'>
                <div className="box-div2">
                    <div className="buttons2">
                        <p className="member2">Saree</p>
                        <div 
                        style={{display: 'flex', flexDirection: 'column'}}
                        className="new-search">
                            <div>
                            <input
                            onChange={(x)=>{
                                setsearch(x.target.value)

                            }}
                            value={search}

                             className="search-input" type="search" name="" id="" placeholder="Product Name" />
                            <button 
                            onClick={()=>{
                                searchFun(search)
                            }}
                            className='btn2'>submit</button>
                            </div>
                        
                            <button
                            style={{marginTop: '8px'}}
                                onClick={() => {
                                    navigate('/productForm/saree')
                                }}
                                className="btn2">+Add New</button>
                        </div>
                    </div>
                    <table className="tablebox2">
                        <thead className='thead'>
                            <tr className="tablehead2">

                                <th>Product</th>
                                <th >Name</th>
                                <th >Price</th>
                                <th>Category</th>
                                <th>Fabric</th>

                                <th>Quantity</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                products.map((val, index) => {
                                    let data = val


                                    //  console.log(val.data())
                                    return (

                                        <tr key={index} className="tablerow2">

                                            <th >
                                                <img className="doc-img2" src={data.images[0].img} alt="" />
                                            </th>
                                            <th>{data.name}</th>
                                            <th>{data.price}</th>

                                            <th>{data.category}</th>
                                            {/* <th>{fabarr[parseInt(data.fabric) - 1]}</th> */}
                                    
                                            <th>{data.fabric}</th>
                                            <th>{data.quantity}</th>
                                            <th >

                                                <BiPencil
                                                    onClick={() => {
                                                        navigate('/editProduct/saree/' + val.id)
                                                    }}
                                                    style={{
                                                        backgroundColor: 'green',
                                                        padding: '4px', borderRadius: '2px',
                                                        color: 'white', fontSize: '20px', margin: '2px'
                                                    }} />

                                                <AiOutlineDelete
                                                    onClick={() => {
                                                        let answer = window.confirm("Are you sure You want to Delete this Product data?");
                                                        if (answer) {
                                               
                                                            deleteProduct(val.id, index)
                                                        }
                                                     
                                                        //
                                                    }}
                                                    style={{
                                                        backgroundColor: 'red',
                                                        padding: '4px', borderRadius: '2px',
                                                        color: 'white', fontSize: '20px', margin: '2px'
                                                    }} />

                                            </th>
                                        </tr>

                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </section>



        </div>

    )
}

export default Sarees