import { async } from '@firebase/util'
import { getAuth } from 'firebase/auth'
import { addDoc, collection, doc, getDoc, getFirestore } from 'firebase/firestore'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import React, { useEffect, useRef, useState } from 'react'
import { app } from '../Fire'
import Progress from '../Progress/Progress'
import './addblouse.css'

function AddBlouse() {


  let db = getFirestore(app)

  const fileInput = useRef(null)


  const [name, setname] = useState("")
  const [fabric, setfabric] = useState("")
  const [dimen, setdimen] = useState("")
  const [price, setprice] = useState("")
  const [quantity, setquantity] = useState("")
  const [desc, setdesc] = useState("")
  const [color, setcolor] = useState("")
  const [colorname, setcolorname] = useState("")


  const [colorarr, setcolorarr] = useState([])

  const [prog, setprog] = useState(false)

  const handleClick = () => {
    fileInput.current.click()
  }


  useEffect(() => {
   
    getcolors()
  }, [])


  let auth = getAuth(app)


  const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }


  const [myimages, setmyimages] = useState([])
  const storage = getStorage(app)


  function deleteImage(obj) {

    const desertRef = ref(storage, '' + obj.path);

    // Delete the file
    deleteObject(desertRef).then(() => {

      var index = myimages.indexOf(obj);
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        myimages.splice(index, 1);
      }

      let some = [...myimages]
      setmyimages(some)

      alert("File Deleted")
    }).catch((error) => {
      alert(error)
    });
  }


  function uploadfun(image) {

    setprog(true)
    const id = auth.currentUser.uid
    const storageRef = ref(storage, `/Products/${id}/${"" + makeid(20)}`)
    const uploadTask = uploadBytesResumable(storageRef, image);


    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(storageRef.fullPath)
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {

        setprog(false)
        alert(error.message)
      },
      () => {

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let duri = downloadURL
          setprog(false)
          let some = [...myimages]
          some.push({ img: downloadURL, path: storageRef.fullPath })
          setmyimages(some)
          console.log('File available at', myimages);

        });
      }
    );


  }



  function validate() {
    if (name == "" || dimen == "" || desc == "" || fabric == "" ||
      colorname == "" || color == "" || price == "" || quantity == "") {
      alert("Please Enter All Feilds")
    } else if (myimages.length == 0) {
      alert("Please Add Images")
    }
    else {
      addBlouse()
    }
  }

  async function addBlouse() {
    setprog(true)
    try {
      await addDoc(collection(db, "blouses"), {
        name: name,
        dimension: dimen,
        desc: desc,
        price: parseInt(price),
        quantity: parseInt(quantity),
        fabric: fabric,
        images: myimages,
        colorName: colorname,
        colorCode: color
      })
      alert("Blouse Added")
      window.history.back()
      setprog(false)
    } catch (e) {
      setprog(false)
      alert(e)
    }
  }


  async function getcolors() {

    try {
      const docref = doc(db, "extras", "mycolors")
      const snapshot = await getDoc(docref)

      if (snapshot.exists()) {
        console.log("yes")
        if (snapshot.data().colors != null) {
          setcolorarr(snapshot.data().colors)
          setcolor(snapshot.data().colors[0].code)
          setcolorname(snapshot.data().colors[0].name)

        }
      }

    } catch (e) {
      alert(e)
    }
  }


  return (
    <div
      style={{
        padding: '20px'
      }}>


        {
          prog ? <Progress/> : null
        }
      <h2 className='member2'>Add Blouse</h2>


      <div className="field field_v1 biget">

        <input
          onChange={(x) => {
            setname(x.target.value)
          }}
          className="field__input" placeholder="e.g. cotton blouse" />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Blouse Name</span>
        </span>



      </div>

      <div className="field field_v1 biget">
        <input className="field__input" placeholder="e.g cotton"
          onChange={(x) => {
            setfabric(x.target.value)
          }} />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Fabric Name</span>
        </span>
      </div>


      <div className="field field_v1 biget">
        <input
          onChange={(x) => {
            setdimen(x.target.value)
          }}
          className="field__input" placeholder="e.g 80 x 10 x 100" />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Dimensions</span>
        </span>
      </div>


      <div className="field field_v1 biget">
        <input
          onChange={(x) => {
            setprice(x.target.value)
          }}
          type='number'
          className="field__input" placeholder="e.g  2000" />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Price</span>
        </span>
      </div>

      <div className="field field_v1 biget">
        <input
          onChange={(x) => {
            setquantity(x.target.value)
          }}
          type='number'
          className="field__input" placeholder="e.g  2000" />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Quantity</span>
        </span>
      </div>


      <div className="field field_v1 biget">
        <input
          onChange={(x) => {
            setdesc(x.target.value)
          }}
          className="field__input" placeholder="e.g  This is best saree" />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Description</span>
        </span>
      </div>


      <label className='blouselabel'>Select Color</label>
      <select onChange={(x) => {
        setcolor(x.target.value)
        let name = x.target.options[x.target.selectedIndex].text
        setcolorname(name)

      }}>
        {
          colorarr.map((val, index) => {
            return (
              <option key={index}
                value={val.code}> {val.name} </option>
            )

          })
        }

      </select>


      <input
        type="file"
        ref={fileInput}
        accept="image/*"
        style={{ margin: '12px 0px 12px 0px', display: 'none' }}

        name="myImage"

        onChange={(event) => {

          const [file] = event.target.files;


          uploadfun(event.target.files[0])

          console.log(event.target.files[0])

        }}
      />


      <div
        style={{
          margin: '12px 0px 12px 0px',
          padding: '12px',
          width: '200px', textAlign: 'center'
        }}
        className='btn2'
        onClick={() => {
          handleClick()
        }}>
        Upload Picture
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>



        {
          myimages.map((val, key) => {
            return <div
              style={{
                display: 'flex',

                margin: '12px',
                flexDirection: 'column'
              }}
              key={key}>
              <img style={{ width: '100px', height: '100px' }}
                src={val.img} />


              <button
                onClick={() => {
                  deleteImage(val)
                }}
              >
                Delete</button>
            </div>
          }


          )

        }
      </div>


      <div
        style={{
          display: 'flex', justifyContent: 'center', margin: '12px'
        }}>
        <button
          onClick={() => {
            validate()
          }}
          className='colorbtn'>
          Submit
        </button>
      </div>


    </div>
  )
}

export default AddBlouse