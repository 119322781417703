import React, { useEffect, useState } from 'react'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { app } from '../Fire';
import { doc, setDoc, getFirestore, collection, serverTimestamp, query, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import Progress from '../Progress/Progress';
import { async } from '@firebase/util';
import { useParams } from 'react-router-dom';




function EditProduct(props) {


    var duri;

    const db = getFirestore(app)

    let auth = getAuth(app)




    const def = "Please Select"

    let params = useParams()

    const [pname, setpname] = useState("")
    const [price, setprice] = useState(0)
    const [quantity, setquantity] = useState(0)
    const [category, setcategory] = useState("")
    const [fabric, setfabric] = useState("")
    const [dimension, setdimension] = useState("")
    const [desc, setdesc] = useState("")
    const [fabarr, setfabarr] = useState([])
    const [catarr, setcatarr] = useState([])
    const [prog, setprog] = useState(false)
    const [color, setcolor] = useState("")

    const [colarr, setcolarr] = useState([])

    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }


    const [myimages, setmyimages] = useState([])
    const storage = getStorage(app)


    function deleteImage(obj) {

        const desertRef = ref(storage, '' + obj.path);

        // Delete the file
        deleteObject(desertRef).then(() => {

            var index = myimages.indexOf(obj);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                myimages.splice(index, 1);
            }

            let some = [...myimages]
            setmyimages(some)

            alert("File Deleted")
        }).catch((error) => {
            alert(error)
        });
    }


    function uploadfun(image) {
        setprog(true)
        const id = auth.currentUser.uid
        const storageRef = ref(storage, `/Products/${id}/${"" + makeid(20)}`)
        const uploadTask = uploadBytesResumable(storageRef, image);


        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(storageRef.fullPath)
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                alert(error.message)
                setprog(false)
                // Handle unsuccessful uploads
            },
            () => {

                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    duri = downloadURL
                    setprog(false)
                    let some = [...myimages]
                    some.push({ img: downloadURL, path: storageRef.fullPath })
                    setmyimages(some)
                    console.log('File available at', myimages);

                });
            }
        );


    }
    async function addData() {


        let productid = makeid(20)
        setprog(true)

        try {
            await updateDoc(doc(db, "products", "" + params.id), {
                name: "" + pname,
                createdAt: serverTimestamp(),
                description: "" + desc,
                category: "" + category,
                price: parseInt("" + price),
                isAvailable: true,
                color: color,
                images: myimages,
                quantity: parseInt(quantity),
                fabric: "" + fabric,
                dimension: "" + dimension,
                type: "" + params.type
            });

            alert("Product Updated")
            window.history.back()
            setprog(false)

        } catch (e) {
            alert(e)
            setprog(false)
        }



    }


    useEffect(() => {
        getExtras()
    }, [])



    async function getExtras() {

        setprog(true)
        try {


            let productRef = doc(db, "products", params.id)
            let productSnapshot = await getDoc(productRef)
            let productData = productSnapshot.data()

            setdimension(productData.dimension)
            setpname(productData.name)
            setprice(productData.price)
            setquantity(productData.quantity)
            setdesc(productData.description)

            setmyimages(productData.images)
            setprog(false)
            let temp1 = [{ name: def, key: def }]
            let temp2 = [{name: def, key: def}]
            let temp3  = [{name: def, key: def}]

            setprog(false)


            props.fabricMap.forEach((val, key)=>{
                temp2.push({name: val, key: key})
            })
            setfabarr(temp2)
            setfabric(productData.fabric)
         
            props.colorMap.forEach((val, key)=>{
                temp3.push({name: val.name, key: key})
            })

            setcolarr(temp3)
            setcolor(productData.color)

            console.log("saree")
            if (params.type == "saree") {
                props.sareeMap.forEach((val, key) => {
                    temp1.push({ name: val, key: key })
                })

                setcategory(productData.category)
                setcatarr(temp1)
               // setcategory(props.sareeMap.get('' + productData.category))
              
            } else {
                props.blouseMap.forEach((val, key) => {
                    temp1.push({ name: val, key: key })
                })
                setcategory(productData.category)
               console.log(temp1)
                setcatarr(temp1)
            
            }


            // let productData = productSnapshot.data()
            // setpname(productData.name)
            // setdimension(productData.dimension)
            // setprice(productData.price)
            // setquantity(productData.quantity)
            // setdesc(productData.description)
            // setmyimages(productData.images)
            // const docref = doc(db, "extras", params.type + "types")
            // const fabricRef = doc(db, "extras", "fabrics")
            // const colorRef = doc(db, "extras", "mycolors")
            // let fabricsnapshot = await getDoc(fabricRef)
            // let colorsnapshot = await getDoc(colorRef)
            // let snapshot = await getDoc(docref)
            // console.log(fabricsnapshot.data())

            // let temp1 = []
            // temp1.push(colorsnapshot.data().colors)

            //  let index=  temp1.indexOf({'code': productData.colorCode,
            //     'name': productData.colorName})
            //     alert(index)

            // setcolor(productData.colorCode)
            // setcolorname(productData.colorName)
            //    setfabric(productData.fabric)
            //    setcategory(productData.category)



            //    setcolorname(colorsnapshot.data().colors[0].name)
            //    setfabric(fabricsnapshot.data().types[0])
            //   setcategory(snapshot.data().types[0])
            // setfabarr(fabricsnapshot.data().types)
            // setcatarr(snapshot.data().types)
            // setcolarr(colorsnapshot.data().colors)

            // setprog(false)

        } catch (e) {
            setprog(false)
            alert(e)
        }

    }

    function validate() {


        if (pname == "" || category == "" ||
            fabric == "" || dimension == "" || desc == "" || color == "") {

            alert("Please Enter All Feilds. Invalid Data")

        } else if (category == def) {
            alert("Please Select Category")
        } else if (fabric == def) {
            alert("Please Select Fabric")
        }
     
        else if (myimages.length == 0) {
            alert("Please Add Images")
        } else {
            addData()
        }

    }




    return (

        <div className="container">


            <h3 style={{marginTop: '12px'}}>Edit {params.type}</h3>

            {
                prog ? <Progress /> : null
            }
            <div style={{width: '70vw'}}>
                <div className="row">
                    <div className="col-25">
                        <label >Product Name</label>
                    </div>
                    <div className="col-75">
                        <input type="text"
                            value={pname}
                            onChange={(x) => {
                                setpname(x.target.value)
                            }}
                            placeholder="Product name.." />
                    </div>
                </div>


                <div className="row">
                    <div className="col-25">
                        <label >Product Category</label>
                    </div>
                    <div className="col-75">

                        <select value={category} onChange={(x) => {
                            setcategory(x.target.value)
                        }}>

                            {
                                catarr.map((val, index) => {
                                    return (
                                        <option 
                                        value={val.key}
                                        key={index}>{val.name}</option>
                                    )
                                })
                            }

                        </select>
                        {/* <input type="text"
              value={category}
              onChange={(x) => {
                setcategory(x.target.value)
              }}
              placeholder="Product Category" /> */}
                    </div>
                </div>



                <div className="row">
                    <div className="col-25">
                        <label >Product Fabric</label>
                    </div>
                    <div className="col-75">


                        <select value={fabric} onChange={(x) => {
                            setfabric(x.target.value)
                        }}>
                            {
                                fabarr.map((val, index) => {
                                    return (
                                        <option key={index} 
                                        value={val.key}
                                        >{val.name}</option>
                                    )

                                })
                            }

                        </select>
                    </div>
                </div>


                <div className="row">
                    <div className="col-25">
                        <label >Product Dimension</label>
                    </div>
                    <div className="col-75">
                        <input type="text"
                            value={dimension}
                            onChange={(x) => {
                                setdimension(x.target.value)
                            }}
                            placeholder="Product Dimension" />
                    </div>
                </div>


                <div className="row">
                    <div className="col-25">
                        <label >Product Price</label>
                    </div>
                    <div className="col-75">
                        <input type='number'
                            value={price}
                            onChange={(x) => {
                                setprice(x.target.value)
                            }}
                            placeholder="Product Price" />
                    </div>
                </div>


                <div className='row'>
                    <div className='col-25'>
                        <label>Select Color</label>
                    </div>

                    <div className='col-75'>

                        <select value={color} onChange={(x) => {
                            setcolor(x.target.value)

                        }}>
                            {
                                colarr.map((val, index) => {
                                    return (
                                        <option key={index}
                                            value={val.key}> {val.name} </option>
                                    )

                                })
                            }

                        </select>

                    </div>

                </div>

                <div className="row">
                    <div className="col-25">
                        <label >Product Quantity</label>
                    </div>
                    <div className="col-75">

                        <input type='number'
                            value={quantity}
                            onChange={(x) => {
                                setquantity(x.target.value)
                            }}
                            placeholder="Product Quantity" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-25">
                        <label >Description</label>
                    </div>
                    <div className="col-75">
                        <textarea
                            value={desc}
                            onChange={(x) => {
                                setdesc(x.target.value)
                            }}
                            placeholder="Write Product Description.." style={{ height: '200px' }}></textarea>
                    </div>
                </div>
                <input
                    type="file"
                    accept="image/*"
                    style={{ margin: '12px 0px 12px 0px' }}

                    name="myImage"

                    onChange={(event) => {

                        const [file] = event.target.files;


                        uploadfun(event.target.files[0])

                        console.log(event.target.files[0])

                    }}
                />


                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {
                        myimages.map((val, key) => {
                            return <div
                                style={{
                                    display: 'flex',

                                    margin: '12px',
                                    flexDirection: 'column'
                                }}
                                key={key}>
                                <img

                                    style={{ width: '100px', height: '100px' }}
                                    src={val.img} />


                                <button
                                    onClick={() => {
                                        deleteImage(val)
                                    }}
                                >
                                    Delete</button>
                            </div>
                        }


                        )

                    }
                </div>


                <div className="row">

                    <button
                        onClick={() => {

                            validate()
                            console.log(myimages)
                        }}
                        style={{ marginTop: '4px' }}
                        className='mybuttons1'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default EditProduct