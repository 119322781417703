import { getAuth } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../Fire'
import './Home.css'

function Home() {


  let auth = getAuth(app)
  let navigate = useNavigate()

  return (

    <div 
   id='main'
    style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      marginTop: '40px'
    }}>

      <h2>DashBoard</h2>

      <button
        onClick={() => {
          navigate('/productForm')
        }}
        className='mybuttons'>
        Add Products
      </button>

      <button
        onClick={() => {
          navigate('/addblouse')
        }}

        className='mybuttons'>
        Add Blouse
      </button>

      <button
        onClick={() => {
          navigate('/myproducts')
        }}
        className='mybuttons'>
        My Products
      </button>

      <button
        className='mybuttons'
        onClick={() => {
          navigate('/addcolor')
        }}>
        Add Color
      </button>

      <button className='mybuttons' onClick={() => {
          navigate('/viewblouse')
      }} > View Blouse </button>


      <button
        onClick={() => {

          try {
            auth.signOut()
            navigate('/')

          } catch (e) {
            alert(e)
          }
        }}
        className='mybuttons'>
        Log Out
      </button>

    </div>
  )
}

export default Home