// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWI_mF539ukV-YsTS56FB1RMpE9DrNSfw",
  authDomain: "sareeweb-4c155.firebaseapp.com",
  projectId: "sareeweb-4c155",
  storageBucket: "sareeweb-4c155.appspot.com",
  messagingSenderId: "158936726181",
  appId: "1:158936726181:web:fe28d518ee1d3c505a6b4c",
  measurementId: "G-FXGMDQXXBQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
