import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { app } from '../Fire'
import './viewblouse.css'
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import Drawer from '../Drawer/Drawer';
import Progress from '../Progress/Progress';
import { BiPencil } from 'react-icons/bi';



function ViewBlouse(props) {


    let temp = []
    let db = getFirestore(app)
    const [arr, setarr] = useState([])

    const [prog, setprog] = useState(false)
    const [search, setsearch] = useState("")
    let navigate = useNavigate()

    useEffect(() => {
        getBlouses()
    }, [])




    async function searchFun(txt) {

        setprog(true)
        try {
            const q = query(collection(db, "products"),
            where('type', '==', 'blouse'),
             where('name', '>=', txt), where('name', '<=', txt + '\uf8ff'))

            const snapshot = await getDocs(q)

            snapshot.forEach((x) => {


                let d1 = x.data()
                d1.id = x.id



                props.fabricMap.forEach((val, key) => {
                    if (d1.fabric == key) {
                        d1.fabric = val
                    }
                })

                props.blouseMap.forEach((val, key) => {
                    if (d1.category == key) {
                        d1.category = val
                    }
                })

                temp.push(d1)
              
                // temp.push(x)
            })
            setarr(temp)
            setprog(false)

        } catch (e) {
            setprog(false)
            console.log(e)
            alert(e)
        }




    }


    async function getBlouses() {
        setprog(true)

        try {
            const q = query(collection(db, "products"), where("type", "==", "blouse"), orderBy("createdAt", "desc"))
            const snapshot = await getDocs(q)
            snapshot.forEach((x) => {


                let d1 = x.data()
                d1.id = x.id



                props.fabricMap.forEach((val, key) => {
                    if (d1.fabric == key) {
                        d1.fabric = val
                    }
                })

                props.blouseMap.forEach((val, key) => {
                    if (d1.category == key) {
                        d1.category = val
                    }
                })

                temp.push(d1)
                // temp.push(x)
            })

            setprog(false)
            setarr(temp)
        } catch (e) {
            setprog(false)
            alert(e)
        }

    }

    async function deleteProduct(id, index) {
        setprog(true)
        try {


            await deleteDoc(doc(db, "products", id))
            alert("Deleted")
            setprog(false)

            let old = [...arr]
            old.splice(index, 1)
            setarr(old)

        } catch (e) {
            setprog(false)
            alert(e)
        }
    }




    return (
        <div id='main'>

            {
                prog ? <Progress /> : null
            }

            <Drawer  name='Blouses'/>

            <section id='main'>
                <div className="box-div2">
                    <div className="buttons2">
                        <p className="member2">Blouses Details</p>



                        <div className="new-search" style={{display: 'flex', flexDirection: 'column'}}>
                            <div>
                            <input 
                            onChange={(x)=>{
                                setsearch(x.target.value)
                            }}
                            value={search}
                            className="search-input" type="search" name="" id="" placeholder="Product Name"/>
                            <button
                            onClick={()=>{
                                searchFun(search)
                            }}
                             className='btn2'>submit</button>
                            </div>
                          
                            <button
                            style={{marginTop: '12px'}}
                                onClick={() => {
                                    //    navigate('/addblouse')
                                    navigate('/productForm/blouse')
                                }}
                                className="btn2">+Add New</button>
                        </div>
                    </div>
                    <table className="tablebox2">
                        <thead 
                        className='thead'>
                            <tr className="tablehead2">
                                <th >Product Id</th>
                                <th>Image</th>
                                <th >Name</th>
                                <th>Category</th>
                                <th>Fabric</th>
                                <th >Price</th>
                                <th>Quantity</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                arr.map((val, index) => {
                                    let data = val

                                    return (

                                        <tr key={index} className="tablerow2">

                                                <th>{data.productId}</th>
                                            <th >
                                                <img className="doc-img2" src={data.images[0].img} alt="" />
                                            </th>
                                
                                            <th>{data.name}</th>
                                            <th >{data.category}</th>
                                            <th>{data.fabric}</th>
                                            <th>{data.price}</th>
                                            <th>{data.quantity}</th>
                                            <th >


                                                <BiPencil
                                                    style={{
                                                        backgroundColor: 'green',
                                                        padding: '4px', borderRadius: '2px',
                                                        color: 'white', fontSize: '20px', margin: '2px'
                                                    }}
                                                    onClick={() => {
                                                        navigate('/editproduct/blouse/' + val.id)
                                                        // deleteProduct(val.id, index)
                                                    }}
                                                />


                                                <AiOutlineDelete
                                                    style={{
                                                        backgroundColor: 'red',
                                                        padding: '4px', borderRadius: '2px',
                                                        color: 'white', fontSize: '20px', margin: '2px'
                                                    }}
                                                    onClick={() => {
                                                        let answer = window.confirm("Are You sure You want to delete this Product")

                                                        if (answer) {

                                                            deleteProduct(val.id, index)
                                                        }
                                                    }}
                                                />

                                            </th>
                                        </tr>

                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </section>






        </div>
    )
}

export default ViewBlouse