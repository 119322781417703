import logo from './logo.svg';
import './App.css';
import ProductForm from './ProductForm/ProductForm';
import Login from './Login/Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './Fire';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './Home/Home';
import MyProducts from './MyProducts/Sarees';
import ProductDetails from './ProductDetails/ProductDetails';
import AddBlouse from './Blouse/AddBlouse';

import ViewBlouse from './ViewBlouse/ViewBlouse';
import Orders from './Orders/Orders';
import SareeForm from './ProductForm/ProductForm';
import Sarees from './MyProducts/Sarees';
import EditProduct from './EditProduct/EditProduct';
import OrderDetails from './OrderDetails/OrderDetails';
import { async } from '@firebase/util';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import Progress from './Progress/Progress';
import ViewColor from './ColorsPage/ViewColor';
import AddColor from './ColorsPage/AddColor';


//export let fabMap = new Map()

function App() {

 
  let navigate = useNavigate()
  let auth = getAuth(app)
  const [fabMap, setfabMap] = useState()
  const [sareeMap, setsareeMap] = useState()
  const [blouseMap, setblouseMap] = useState()

  const [colorMap, setcolorMap] = useState()
  const [loaded, setloaded] = useState(false)



  useEffect(() => {
    
   checkauth()
   getAllExtras()
  }, [])




        async function getAllExtras(){
          try{
            let db = getFirestore(app)
            const fabref = doc(db, "extras", "fabrics")
            const sareeRef = doc(db, "extras", "sareetypes")
            const blouseRef = doc(db, "extras", "blousetypes")
            const colorRef = doc(db, "extras","mycolors")

            const blousesnap = await getDoc(blouseRef)
            const sareesnap = await getDoc(sareeRef)
            const snapshot = await getDoc(fabref)
            const colorsnap = await getDoc(colorRef)
          setfabMap(new Map(Object.entries(snapshot.data().mymap)))
          setsareeMap(new Map(Object.entries(sareesnap.data().mymap)))
          setblouseMap(new Map(Object.entries(blousesnap.data().mymap)))
          setcolorMap(new Map(Object.entries(colorsnap.data().mymap)))

            setloaded(true)
          }catch(e){
            setloaded(true)
            alert(e)
          }  
        }


  function checkauth(){
  
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if(window.location.pathname == '/'){
         navigate('/myproducts')
        }
      } else {
      
       console.log("no login")
        navigate('/')
     
      }
    });
  }


  return (


    <div>
      {
        loaded ?
        <Routes>
        <Route index element={<Login/>} />
        <Route path='/home' element={<Home />}/>

        <Route path='/productForm/:type' element={<ProductForm fabricMap={fabMap} 
        sareeMap ={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>}/>

        <Route path='/myproducts' element={<Sarees fabricMap={fabMap} 
        sareeMap ={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>}/>


        <Route path='/productdetails' element={<ProductDetails/>}/>
        <Route path='/addblouse' element={<AddBlouse/>}/>
        <Route path='/viewcolor' element={<ViewColor/>}/>
        <Route path='/addcolor' element={<AddColor/>}/>
        <Route path='/editcolor/:name/:code/:key' element={<AddColor/>}/>
        <Route path='/viewBlouse' element={<ViewBlouse fabricMap={fabMap} 
        sareeMap ={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>}/>

        
        <Route path='/orders' element={<Orders/>}/>

        <Route path='/editProduct/:type/:id' element={<EditProduct fabricMap={fabMap} 
        sareeMap ={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>}/>


        <Route path='/orderDetails/:id' element={<OrderDetails fabricMap={fabMap} 
        sareeMap ={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>}/>
       
    </Routes> : <Progress/>
      }
    </div>

   
 
  
  );
}

export default App;
