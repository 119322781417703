import { async } from '@firebase/util'
import { collection, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Drawer from '../Drawer/Drawer'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

function AddColor() {

    const [colorarr, setcolorarr] = useState([])

    const [prog, setprog] = useState(false)
    const [colorMap, setcolorMap] = useState()

    let params = useParams()
    const [color, setcolor] = useState("")
    const [name, setname] = useState("")


    let db = getFirestore(app)


    function validate() {
        if (color == "" || name == "") {
            alert("Please Select Color and Name it")
        } else {

            if(params.name != null){
                editFun(name,color)
            }else{
                addFun(name, color)
            }
          
        }
    }



    async function editFun(name, code){
        setprog(true)
        try{
            let obj = new Object()
          
       
                colorMap.forEach((element, key) => {
                    obj[''+ key] = element
                   
                
                })
              
                obj[''+params.key] = { name: name, code: code }
              
                const addRef = doc(db, "extras", "mycolors")
                await setDoc(addRef, {
                    mymap: obj
                })
                alert("added")
    
                    window.history.back()   
                  
          
        }catch(e){
            alert(e)
        }
    }


    async function addFun(name, code) {

        setprog(true)
        
        try {
          let newKey = 0
            let obj = new Object()
            if (colorMap != null) {

                colorMap.forEach((element, key) => {
                    obj[''+ key] = element
                    newKey = parseInt("" + key)
                    console.log("last"+key)
                  
                })
              

                obj[''+(newKey+1)] = { name: name, code: code }
              
                 
                console.log(colorMap.size)
            } else {
                obj['0'] = { name: name, code: code }
  
            }
            
            const addRef = doc(db, "extras", "mycolors")
            await setDoc(addRef, {
                mymap: obj
            })
            alert("added")

              
                window.history.back()  
             
        } catch (e) {
            setprog(false)
            alert(e)
        }

    }

    useEffect(() => {
        getcolors()
        if(params.name != null){
            setname(params.name)
        }

        if(params.code != null){
            setcolor('#'+params.code)
        }
    }, [])



    async function getcolors() {

        setprog(true)
        try {
            const docref = doc(db, "extras", "mycolors")
            let temp1 = []
            const snapshot = await getDoc(docref)
            if (snapshot.exists()) {

                if (snapshot.data().mymap != null) {
                    console.log(snapshot.data().mymap)

                    let map = new Map(Object.entries(snapshot.data().mymap))


                    setcolorMap(map)

                    map.forEach((val, key) => {
                        temp1.push({ data: val, key: key })
                    });
                    setcolorarr(temp1)
                }

                setprog(false)
            }
        } catch (e) {
            setprog(false)
            alert(e)
        }
    }



    return (
        <div style={{ padding: '12px' }}>

            {
                prog ? <Progress/> : null
            }
            <button 
            onClick={()=>{
                window.history.back()
            }}
            className='btn2'>Back</button>
            <h2>Add Color</h2>

            <div style={{
                display: "flex", flexDirection: 'column', alignItems: 'center',
                justifyContent: 'center', height: '100vh'
            }}>




                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input
                        style={{
                            margin: '12px', width: '300px', height: '200px', outline: 'none',
                            borderWidth: '0px'
                        }}
                        value={color}
                        onChange={(x)=>{
                            setcolor(x.target.value)
                        }}
                        type='color' />


                    <div style={{
                        display: 'flex', height: '200px', marginLeft: '20px',
                        flexDirection: 'column', justifyContent: 'space-between'
                    }}>
                        <p>Colors which are added / Edited here are shown to
                            <br />user and can be choosen while adding products</p>

                            <div style={{display: 'flex'}}> 

                            <input type='text' value={name} onChange={(x)=>{
                                setname(x.target.value)
                        }}/>

                        <button 
                        onClick={()=>{
                            validate()
                        }}
                        className='btn2'>Submit</button>

                            </div>
                  
                    </div>


                </div>

            </div>



        </div>
    )
}

export default AddColor