import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Drawer from '../Drawer/Drawer'
import { app } from '../Fire'
import { FaShopify } from "react-icons/fa";

import Progress from '../Progress/Progress'
import { useNavigate } from 'react-router-dom';

function Orders() {


    let db = getFirestore(app)
    let temp = []
    const [orders, setorder] = useState([])
    let navigate = useNavigate()
    const [prog, setprog] = useState(false)

    useEffect(() => {
      
        getOrders()
    }, [])
    

    const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
      }
    
    async function getOrders(yesterday, today){
        setprog(true)
        console.log(today)
        try{
            let q 

            if(today != null && yesterday != null){
                console.log('apply')
    
                 q = query(collection(db, "orders") ,where('time', '>', yesterday),where('time', "<=", today), limit(100))
            }else{
                q = query(collection(db, "orders"),orderBy('time', 'desc'), limit(100))
            }
         
            const snapshot = await getDocs(q)
            snapshot.forEach((element)=>{
                temp.push(element)
            })
            setorder(temp)
            setprog(false)
        }catch(e){
            setprog(false)
            alert(e)
        }
     

    }

  return (
    <div id='main'>
        {
            prog ? <Progress/> : null
        }
        <Drawer name='Orders'/>
   

        <div id='main' style={{
        padding: '20px'
    }}>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Orders</h4>

        {/* <input type={'date'}  onChange={(x)=>{

            let date  = new Date(x.target.value)
           var yesterday = new Date(date + 86400000 *2); // that is: 24 * 60 * 60 * 1000
            console.log(date)
            console.log(yesterday)
            var today = new Date(date - 86400000)
         getOrders(today, yesterday)

        }}/> */}
        </div>


        <table className="tablebox2">
                        <thead className='thead'>
                            <tr className="tablehead2">


                        <th>Order</th>
                        <th>Date</th>
                                <th>Total</th>
                                <th >Mobile</th>
                                <th >Payment Id</th>
                      
                            </tr>
                        </thead>

                        <tbody>

                            {
                                orders.map((val, index) => {
                                    let data = val.data()
                                    let displayprice = 0;
                                 
                                    let mydate = new Date(data.time.seconds * 1000)
                                    let strDate = mydate.toDateString()
                                    console.log(val.data())
                                    return (

                                        <tr 
                                        onClick={()=>{
                                            navigate('/orderDetails/'+val.id)
                                            // alert(""+val.id)
                                        }}
                                        key={index} className="tablerow2">
                                            <th>
                                                
                                                <FaShopify
                                                style={{
                                                    
                                                    fontSize: '32px'
                                                }}/>
                                            </th>

                                            <th>{strDate}</th>
                                            <th >
                                             {data.totalAmount}
                                            </th>
                                            <th>{data.mobile}</th>
                                            <th>{data.transId}</th>
                                           
                                         
                                        </tr>

                                    

                                    )


                                })
                            }



                        </tbody>
                    </table>
{/* 
        {
            orders.map((val, index)=>{
                return(
                
                
                )
            })
        } */}

        </div>



     
    </div>
  )
}

export default Orders