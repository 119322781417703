import { async } from '@firebase/util'
import { addDoc, arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { app } from '../Fire'
import './addcolor.css'
import { AiOutlineDelete } from "react-icons/ai";
import Drawer from '../Drawer/Drawer'
import { deleteObject } from 'firebase/storage'
import Progress from '../Progress/Progress'
import { BiPencil } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'



function ViewColor() {

    let temp = []
    let navigate = useNavigate()

    const [colorcode, setcolorcode] = useState("")
    const [cname, setcname] = useState("")

    const [colorarr, setcolorarr] = useState([])
    const [colorMap, setcolorMap] = useState()


    const [prog, setprog] = useState(false)


    let db = getFirestore(app)
    useEffect(() => {
      
        getcolors()
        
    }, [])




  

    async function deleteColor(key) {


        setprog(true)

        try {

            let q = query(collection(db, "products"), where("color", "==", key))
            let mdata = await getDocs(q)

            if (mdata.size != 0) {
                alert("A Product Already Has this color")
                return
            }

            let temp = colorMap
            let obj = new Object()
            temp.delete('' + key)

            temp.forEach((element, key) => {
                obj['' + key] = element
            })


            const deleteRef = doc(db, "extras", "mycolors")
            await setDoc(deleteRef, {
                mymap: obj
            })
            setprog(false)
            window.location.reload()

            alert("Deleted")
        } catch (e) {
            setprog(false)
            alert(e)
        }
    }





    async function getcolors() {

        setprog(true)
        try {
            const docref = doc(db, "extras", "mycolors")
            let temp1 = []
            const snapshot = await getDoc(docref)
            if (snapshot.exists()) {

                if (snapshot.data().mymap != null) {
                    console.log(snapshot.data().mymap)

                    let map = new Map(Object.entries(snapshot.data().mymap))


                    setcolorMap(map)

                    map.forEach((val, key) => {
                        temp1.push({ data: val, key: key })
                    });
                    setcolorarr(temp1)
                }

                setprog(false)
            }
        } catch (e) {
            setprog(false)
            alert(e)
        }
    }





    function getfontColor(bgColor, lightColor, darkColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return (L > 0.179) ? darkColor : lightColor;
    }

    return (
        <div id='main'>





            {
                prog ? <Progress /> : null
            }

            <Drawer name='Colors' />


            <div
            style={{padding: '12px'}}
             id='main' >


            <div style={{
                padding: '12px',
                margin: '12px',
                display: 'flex', justifyContent: 'space-between'
            }}>


                <h4>Your Colors</h4>

                <button

                    onClick={() => { 
                        navigate('/addcolor')
                     }}
                    className="btn2">+Add New</button>
            </div>



            <table

             className='tablebox2'>
                <thead className='thead'>
                    <tr className="tablehead2">
                        <th className='tablehead2'>S.NO</th>
                        <th>Color</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>

                </thead>

                <tbody>
                    {
                        colorarr != null ?
                            colorarr.map((val, index) => {
                                let data = val.data
                                return (
                                    <tr key={index} className="tablerow2">

                                        <th>{index + 1}</th>
                                        <th>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p style={{
                                                    borderRadius: '8px',
                                                    height: '30px', width: '30px', background: '' + data.code
                                                }}>

                                                </p>
                                            </div>

                                        </th>
                                        <th>{data.name}</th>
                                        <th>
                                            <BiPencil
                                            onClick={()=>{
                                                navigate('/editcolor/'+data.name+'/'+data.code.replace('#','')+'/'+val.key)
                                            }}
                                                style={{
                                                    backgroundColor: 'green',
                                                    padding: '4px', borderRadius: '2px',
                                                    color: 'white', fontSize: '20px', margin: '2px'
                                                }} />


                                            <AiOutlineDelete
                                                style={{
                                                    backgroundColor: 'red',
                                                    padding: '4px', borderRadius: '2px',
                                                    color: 'white', fontSize: '20px', margin: '2px'
                                                }}
                                                onClick={() => {
                                                    deleteColor(val.key)
                                                    //  deleteProduct(val.id, index)
                                                }}
                                            />
                                        </th>
                                    </tr>
                                )
                            })
                            : <h1>No colors Found</h1>
                    }
                </tbody>

            </table>

            <div style={{
                display: 'flex',
                overflow: 'scroll',
                scrollbarWidth: 'none'

            }}>


             
            </div>

            </div>




        </div>
    )

}

export default ViewColor
